// extracted by mini-css-extract-plugin
export var column = "Footer__column__tnWpt";
export var contentGrid = "Footer__contentGrid__HTft4";
export var copyright = "Footer__copyright__mBh0k";
export var copyrightContainer = "Footer__copyrightContainer__l84rb";
export var footer = "Footer__footer__Disvq";
export var footerLogo = "Footer__footerLogo__NO2zo";
export var footnoteLeft = "Footer__footnoteLeft__dtszH";
export var footnoteRight = "Footer__footnoteRight__ieuvB";
export var footnoteWrapper = "Footer__footnoteWrapper__HMP92";
export var logoSocialContainer = "Footer__logoSocialContainer__PM4pb";
export var row = "Footer__row__FIzFH";
export var socialContainer = "Footer__socialContainer__WPeBX";
export var socialElement = "Footer__socialElement__ZimAb";
export var socialElementBg = "Footer__socialElementBg__ccTeX";
export var wrapper = "Footer__wrapper__NnmHO";